interface IConfig {
  baseURL: string;
}

interface IDefaultConfig {
  port: number;
}

interface IDateTimeFormatConfig {
  dateViewFormat: string;
}
interface IMaskFormatConfig {
  date: string;
  phone: string;
  zip: string;
}
interface IMode {
  LOCAL: IConfig;
  DEV: IConfig;
  QA: IConfig;
  UAT: IConfig;
  PROD: IConfig;
}

const mode: IMode = {
  LOCAL: {
    baseURL: 'http://localhost:4000/api',
  },
  DEV: {
    baseURL: 'https://dev-api.mat-rx.com/api',
  },
  QA: {
    baseURL: 'https://dev-api.mat-rx.com/api',
  },
  UAT: {
    baseURL: 'https://uat-api.mat-rx.com/api',
  },
  PROD: {
    baseURL: 'https://api.mat-rx.com/api',
  },
};

const defaultConfig: IDefaultConfig = {
  port: 3000,
};

const dateTimeFormatConfig: IDateTimeFormatConfig = {
  dateViewFormat: 'MM/DD/YYYY',
};

const maskFormatConfig: IMaskFormatConfig = {
  date: '##/##/####',
  phone: '(###) ###-####',
  zip: '#####',
};

const modeConfig =
  mode[(process.env.REACT_APP_ENVIRONMENT as keyof IMode) || 'DEV'];

const config: IConfig &
  IDefaultConfig &
  IDateTimeFormatConfig &
  IMaskFormatConfig = {
  ...defaultConfig,
  ...modeConfig,
  ...dateTimeFormatConfig,
  ...maskFormatConfig,
};

export default config;
